import React from "react";
import { useGlobalContext } from "../../../../common/contexts/GlobalContext";
import { copyToClipboard } from "../../../../common/utils/copyToClipboard";
import { IoDocumentText } from "react-icons/io5";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { BsCopy } from "react-icons/bs";

import { Drawer as AntDrawer, Divider } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import ParseDateTimeTz from "./../../../../common/components/UI/ParseDateTimeTz";
import ParseAmount from "../../../../common/components/UI/ParseAmount";
import { useBalanceContext } from "../../context/BalanceContext";
import { useGetPayout } from "../../hooks/useGetPayout";
import { TbProgress } from 'react-icons/tb';

const PayoutDrawer = () => {
  const { isMobile } = useGlobalContext();
  const { showDrawer, setShowDrawer, payoutId } = useBalanceContext();
  const { payoutDetails, isLoadingTxn } =
    useGetPayout({ payoutId });

  const transactionsDetails = [
    {
      title: "Status",
      value: (
        <div
          className={`flex gap-1 items-center justify-center px-2 rounded ${
            {
              SUCCESS: "bg-[#20BD96]",
              FAILED: "bg-[#e0343c]",
              INITIATED: "bg-gray-600",
              PROCESSING: "bg-blue-600",
            }[payoutDetails?.status] || ""
          }`}
        >
          {
            {
              SUCCESS: <FaCheck size={16} />,
              FAILED: <RxCross2 size={18} />,
              INITIATED: <FaRegClock size={15} />,
              PROCESSING: <FaRegClock size={15} />,
            }[payoutDetails?.status]
          }
          {payoutDetails?.status || "Unknown status"}
        </div>
      ),
    },
    {
      title: "Payout details",
      value: (
        <div className="flex items-center justify-center gap-2">
          <ParseDateTimeTz dateTime={payoutDetails?.untilTime} />
        </div>
      ),
    },
    {
      title: "Files",
      value:
        payoutDetails?.files?.length > 0 ? (
          <div className="flex flex-col gap-2">
            {payoutDetails?.files?.map((file) => (
              <div key={file} className="flex items-center justify-start gap-2">
                <a
                  href={file?.fileUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center gap-2"
                >
                  <IoDocumentText size={16} />
                  {file?.fileName}
                </a>
              </div>
            ))}
          </div>
        ) : (
          <div>No files found</div>
        ),
    },
  ];

  const hideDrawer = () => {
    setShowDrawer(false);
  };

  const renderContent = () => {
    if (isLoadingTxn) {
      return <TbProgress className="animate-spin" />;
    }

    if (!payoutDetails) {
      return <div>No details found</div>;
    }

    return (
      <>
        {/* Header */}
        <div className="flex items-center justify-between text-2xl font-medium">
          Payout Details{" "}
          <IoClose
            onClick={hideDrawer}
            className="opacity-50 cursor-pointer hover:opacity-100"
          />
        </div>

        {/* Status Header */}
        <div className="py-2">
          {/* Header */}
          <div className="">
            <div className="flex items-center justify-start gap-4 py-4">
              <MdOutlineAccountBalanceWallet
                className="p-3 bg-black rounded-full drop-shadow-xl"
                size={50}
              />
              <div className="flex flex-col w-full">
                <div className="opacity-50">Amount</div>
                <div className="flex items-start justify-between gap-4">
                  <div className="text-3xl font-semibold">
                    <ParseAmount
                      amount={payoutDetails?.amount || 0}
                      currencySize="2xl"
                      divideBy100={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* intendId */}
          <div className="mt-2 p-2 bg-[#242424] rounded-md drop-shadow-xl w-fit">
            <div className="flex items-center justify-between gap-2">
              <div className="flex flex-row gap-2">
                <div className="opacity-50">Payout Id : </div>
                <div className="opacity-80">{payoutId}</div>
              </div>
              <div
                className="flex items-center gap-2 text-blue-600 cursor-pointer"
                onClick={() => copyToClipboard(payoutId)}
              >
                <span>copy</span>
                <BsCopy />
              </div>
            </div>
          </div>
        </div>

        <Divider />
        {/* Details */}
        <div>
          <div className="text-xl font-normal">Details</div>
          <div className="py-2">
            {transactionsDetails.map((item, index) => {
              return (
                <div key={index} className="flex justify-between py-2">
                  <span className="opacity-50">{item?.title}</span>{" "}
                  <span className="font-medium">{item?.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <AntDesignContext>
      <AntDrawer
        zIndex={1100}
        className="bg-white rounded-xl"
        open={showDrawer}
        onClose={hideDrawer}
        closeIcon={false}
        width={isMobile ? "85%" : "30%"}
      >
        {renderContent()}
      </AntDrawer>
    </AntDesignContext>
  );
};

export default PayoutDrawer;
