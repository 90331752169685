import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { Drawer as AntDrawer, Divider, Collapse } from "antd";
import { useNavigate } from "react-router-dom";

// Utilities
import { isEmpty } from "lodash";
import { copyToClipboard } from "../../../../common/utils/copyToClipboard";
import { useGlobalContext } from "../../../../common/contexts/GlobalContext";
import ParseDateTimeTz from "../../../../common/components/UI/ParseDateTimeTz";
import ParseAmount from "../../../../common/components/UI/ParseAmount";

// Icons
import { IoClose } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { BsCopy } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { TbProgress } from "react-icons/tb";
import { FaUserCircle } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { usePaymentLinksContext } from "../../contexts/PaymentLinksContext";
import { useGetOneTimePayment } from "./../../hooks/useGetOneTimePayment";

const OneTimeDrawer = () => {
  const { isMobile } = useGlobalContext();
  const { showDrawer, setShowDrawer, xLinkId } = usePaymentLinksContext();
  const navigate = useNavigate();

  const { oneTimeLinkDetails, isLoadingTxn } = useGetOneTimePayment({
    xLinkId,
  });

  const hideDrawer = () => {
    setShowDrawer(false);
  };

  const transactionsDetails = [
    {
      title: "Status",
      value: (
        <div
          className={`flex gap-1 items-center justify-center px-2 rounded ${
            {
              ACTIVE: "bg-[#20BD96]",
              EXPIRED: "bg-[#e0343c]",
              COMPLETED: "bg-gray-600",
            }[oneTimeLinkDetails?.status] || ""
          }`}
        >
          {
            {
              ACTIVE: <FaCheck size={16} />,
              EXPIRED: <RxCross2 size={18} />,
              COMPLETED: <FaCheck size={16} />,
            }[oneTimeLinkDetails?.status]
          }
          {oneTimeLinkDetails?.status || "Unknown status"}
        </div>
      ),
    },
    {
      title: "Created at",
      value: (
        <div className="flex items-center justify-center gap-2">
          <ParseDateTimeTz
            dateTime={oneTimeLinkDetails?.createdAt}
            onlyDate={false}
          />
        </div>
      ),
    },
    {
      title: "Receipt id",
      value: oneTimeLinkDetails?.receiptId || "-",
    },
    {
      title: "Short URL",
      value: (
        <div
          className="flex items-center justify-center gap-2 cursor-pointer hover:text-blue-400"
          onClick={() => copyToClipboard(oneTimeLinkDetails?.shortUrl)}
        >
          {oneTimeLinkDetails?.shortUrlCode} <BsCopy size={10} />
        </div>
      ),
    },
    {
      title: "Intent id",
      value: (
        <div
          onClick={() => {
            const ids = [oneTimeLinkDetails?.intentId];
            navigate(
              `/transactions?search-by-ids=${encodeURIComponent(
                JSON.stringify(ids)
              )}`
            );
          }}
          className="flex items-center justify-center gap-2 cursor-pointer hover:text-blue-400"
        >
          {oneTimeLinkDetails?.intentId || "-"} <FiExternalLink size={12} />
        </div>
      ),
    },
  ].filter(Boolean);

  const billingDetailsContent = [
    {
      title: "Name",
      value: oneTimeLinkDetails?.customerDetails?.name || "-",
    },
    {
      title: "Email",
      value: oneTimeLinkDetails?.customerDetails?.email || "-",
    },
  ];

  const billingDetails = [
    {
      key: "1",
      label: <div className="opacity-50">Customer details</div>,
      children: (
        <div>
          {billingDetailsContent.map((item, index) => {
            return (
              <div key={index} className="flex justify-between py-2">
                <span className="opacity-50">{item?.title}</span>{" "}
                <span className="pr-2 font-medium text-ellipsis">
                  {item?.value}
                </span>
              </div>
            );
          })}
        </div>
      ),
      extra: (
        <div className="flex items-center gap-2 font-medium">
          <FaUserCircle />
          {oneTimeLinkDetails?.customerDetails?.name}
        </div>
      ),
    },
  ];

  const renderContent = () => {
    if (isLoadingTxn) {
      return <TbProgress className="animate-spin" />;
    }

    if (isEmpty(oneTimeLinkDetails)) {
      return <div>No payment link details found</div>;
    }

    return (
      <div className="" id="subsDrawers">
        {/* Header */}
        <div className="flex items-center justify-between text-2xl font-medium">
          Payment Link Details{" "}
          <IoClose
            onClick={hideDrawer}
            className="opacity-50 cursor-pointer hover:opacity-100"
          />
        </div>

        {/* Status Header */}
        <div className="py-2">
          {/* Header */}
          <div className="">
            <div className="flex items-center justify-start gap-4 py-4">
              <GrTransaction
                className="p-4 bg-black rounded-full drop-shadow-xl"
                size={50}
              />
              <div className="flex flex-col w-full">
                <div className="opacity-50">Amount</div>
                <div className="flex items-start justify-between gap-4">
                  <div className="text-3xl font-semibold">
                    <ParseAmount
                      amount={oneTimeLinkDetails?.amount || 0}
                      currency={oneTimeLinkDetails?.currency}
                      currencySize="2xl"
                      divideBy100={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* intendId */}
          <div className="mt-2 p-2 bg-[#242424] rounded-md drop-shadow-xl w-fit">
            <div className="flex items-center justify-between gap-2">
              <div className="flex flex-row gap-2">
                <div className="opacity-50">Link Id: </div>
                <div className="opacity-80">{xLinkId}</div>
              </div>
              <div
                className="flex items-center gap-2 text-blue-600 cursor-pointer"
                onClick={() => copyToClipboard(xLinkId)}
              >
                <span>copy</span>
                <BsCopy />
              </div>
            </div>
          </div>
        </div>

        <Divider />
        {/* Details */}
        <div>
          <div className="text-xl font-normal">Details</div>
          <div className="py-2">
            {transactionsDetails.map((item, index) => {
              return (
                <div key={index} className="flex justify-between py-2">
                  <span className="opacity-50">{item?.title}</span>{" "}
                  <span className="font-medium">{item?.value}</span>
                </div>
              );
            })}
            {oneTimeLinkDetails?.customerDetails && (
              <Collapse
                ghost
                items={billingDetails}
                className="mt-0"
                expandIconPosition={"end"}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <AntDesignContext>
      <AntDrawer
        zIndex={1100}
        className="bg-white rounded-xl"
        open={showDrawer}
        onClose={hideDrawer}
        closeIcon={false}
        width={isMobile ? "85%" : "30%"}
      >
        {renderContent()}
      </AntDrawer>
    </AntDesignContext>
  );
};

export default OneTimeDrawer;
